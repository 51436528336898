.htmlContainer {
  table {
    border: 1px solid #dee2e6;
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    border-collapse: collapse;
    font-size: 16px;
    margin-bottom: 30px;
  }
  thead th {
    vertical-align: bottom;
    padding: 0.75rem;
    border: 1px solid #dee2e6;
    border-bottom: 2px solid #dee2e6;
  }
  th {
    border: 1px solid #dee2e6;
    padding: 12px;
    text-align: left;
    vertical-align: top;
  }
  td {
    padding: 12px;
    border: 1px solid #dee2e6;
    vertical-align: top;
  }
}
